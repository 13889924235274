<template>
  <!-- eslint-disable -->
  <div v-if="$roles($store.state.user.uid_roles, 'transaction')" class="page px-4 px-md-4 pt-4">
    <div class="d-flex px-0 px-md-4 pb-4 align-center justify-space-between">
      <span class="font-weight-bold">
        Transaction
      </span>
      <span class="caption-small">
        Home > Transaction
      </span>
    </div>
    <v-row no-gutters>
      <v-col cols="12" class="px-md-4">
        <v-row no-guttrs>
          <v-col cols="12" class="col-md-3">
            <v-select
              v-model="view"
              prepend-inner-icon="mdi-chart-box-outline"
              label="View"
              :items="[
                { value: 'seller', name: 'Sell' }
              ].concat(
                $roles($store.state.user.uid_roles, 'transaction-purchase') ? [{ value: 'pembelian', name: 'Buy / Purchase' }] : []
              )"             
              item-value="value"
              item-text="name"
              block
              dense
              hide-details
              outlined
              color="indigo"
              autocomplete="off"
            />
            <!-- <v-select
              v-model="view"
              prepend-inner-icon="mdi-chart-box-outline"
              label="View"
              :items="[
                { value: 'seller', name: 'Sell' }
              ].concat(
                $roles($store.state.user.uid_roles, 'transaction-purchase') ? [{ value: 'pembelian', name: 'Buy / Purchase' }] : []
              ).concat(
                $roles($store.state.user.uid_roles, 'transaction-stock-moving') ? [{ value: $store.state.user.role === 'pusat' ? 'inventory' : 'buyer', name: $store.state.user.role === 'pusat' ? 'Stock Moving' : 'Buy' }] : []
              )"             
              item-value="value"
              item-text="name"
              block
              dense
              hide-details
              outlined
              color="indigo"
              autocomplete="off"
            /> -->
          </v-col>
          <v-col :cols="view === 'pembelian' ? 6 : 4" class="col-md-3">
            <v-select
              v-model.number="trxStokistFilter"
              :disabled="parseInt($store.state.user.isstockist) ? true : false"
              label="Select Source"
              :items="[{ id: 0, user_name: 'All' }].concat($store.state.stokist)"
              item-value="id"
              item-text="user_name"
              block
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              autocomplete="off"
              @change="!trxStokistFilter ? trxSyncType = 0 : ''"
            />
          </v-col>
          <v-col v-if="view !== 'pembelian'" cols="4" class="col-md-3">
            <v-select
              v-if="view === 'inventory'"
              v-model.number="trxSyncType"
              :disabled="!trxStokistFilter"
              label="Sync Type"
              :items="[
                { value: 0, name: 'All' },
                { value: 1, name: 'Sync In' },
                { value: 2, name: 'Sync Out' },
              ]"
              item-value="value"
              item-text="name"
              block
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              autocomplete="off"
            />
            <v-select
              v-else
              v-model.number="trxStatusMemberType"
              label="User Type"
              :disabled="view !== 'seller'"
              :items="$store.state.user.role === 'pusat' ? [
                { value: '', name: 'All' },
                { value: 'reseller', name: 'Reseller' },
                { value: 'customer', name: 'Customer' }
              ] : []"
              item-value="value"
              item-text="name"
              block
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              autocomplete="off"
            />
          </v-col>
          <!-- <v-col v-if="view === 'inventory' && trxSyncType" cols="12" class="col-md-3">
            <v-select
              v-model.number="trxSourcePembelian"
              :label="trxSyncType === 1 ? 'Buy From Supplier?' : 'Sell To Eksternal?'"
              :items="[
                { id: 0, name: 'None' },
                { id: 1, name: 'Yes' }
              ]"
              item-value="id"
              item-text="name"
              block
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              autocomplete="off"
            />
          </v-col> -->
          <v-col :cols="view === 'pembelian' ? 6 : 4" :class="[view === 'pembelian' ? 'col-md-6' : 'col-md-3' ]">
              <!-- 0 = waiting payment : 1 = pending : 2 = processed : 3 = shipped : 4 = complete : 99 = canceled : 100 = deleted -->
            <v-select
              v-model.number="trxStatusFilter"
              label="Status"
              :items="(view === 'seller' ? [
                { value: 9999, name: 'All' },
                { value: 0, name: 'Waiting Payment' }
              ] : [
                { value: 9999, name: 'All' }
              ]).concat([
                { value: 1, name: 'Pending' },
                { value: 2, name: 'Processed' },
                { value: 3, name: 'Shipped' },
                { value: 4, name: 'Complete' },
                { value: 99, name: 'Canceled' }
              ]).concat($store.state.user.role === 'pusat' || $store.state.user.role === 'agen' ? [{ value: 100, name: 'Deleted' }] : [])"
              item-value="value"
              item-text="name"
              block
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              autocomplete="off"
            />
          </v-col>
          <v-col cols="12" class="col-sm-4">
            <v-text-field
              v-model="search"
              label="Search Transaction"
              outlined
              append-icon="mdi-magnify"
              block
              dense
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              autocomplete="off"
              hide-details
            />
          </v-col>
          <v-col cols="12" class="col-md-4">
            <v-menu
              ref="menu"
              v-model="menu"
              :return-value.sync="dates"
              left
              offset-y
              :close-on-content-click="false"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateRangeText"
                  label="Date Ranges"
                  append-icon="mdi-calendar-outline"
                  readonly
                  outlined
                  dense
                  hide-details
                  clearable
                  :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                  class="accent"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dates = []"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                light
                class="force-light-font"
                range
              >
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize"
                  small
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  class="text-capitalize"
                  small
                  @click="$refs.menu.save(dates)"
                >
                  Ok
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col v-if="view !== 'inventory' && view !== 'pembelian'" cols="6" class="col-md-2">
            <v-select
              v-model="trxSourceFilter"
              label="Order Source"
              :items="[{ id: '', name: 'All Source' }].concat($store.state.trxSource)"
              item-value="id"
              item-text="name"
              block
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              autocomplete="off"
              @change="trxUserAdminFilter = 0"
            />
          </v-col>
          <v-col v-if="view !== 'inventory' && view !== 'pembelian'" cols="6" class="col-md-2">
            <v-select
              v-model.number="trxUserAdminFilter"
              label="Select Admin"
              :items="[{ id: 0, name: 'All' }].concat($store.state.user_admin).filter(r => trxStokistFilter ? (parseInt(r.master) === trxStokistFilter) : true)"
              :item-text="(v) => {
                return v.name + (v.master_name ? ' (' + v.master_name + ')' : '')
              }"
              item-value="id"
              block
              outlined
              dense
              hide-details
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="accent"
              autocomplete="off"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="px-md-4">
        <v-card class="px-4 accent pt-4 text-center">
          <v-data-table
            v-model="dialogInvoice.multiple.data"
            :show-select="dialogInvoice.multiple.active"
            :headers="(view === 'inventory' || view === 'pembelian') ? headersInventory.map(r => { (r.value === 'stock_move' ? (r.text = (view === 'pembelian' ? 'Vendor' : 'Stock Move')) : ''); return r }) : headers"
            :items="table"
            item-key="id"
            :server-items-length="tableTotal"
            :page.sync="tablePage"
            :items-per-page.sync="tableLimit"
            :options.sync="options"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50, 100]
            }"
            :loading="tableLoading"
            dense
            class="accent"
            loading-text="Loading... Please wait"
          >
            <template v-slot:top>
              <div class="d-flex fill-width align-center">
                <v-switch
                  v-model="dialogInvoice.multiple.active"
                  label="Multiple Print"
                  class="mr-4"
                  color="primary"
                />
                <v-btn v-if="dialogInvoice.multiple.active && dialogInvoice.multiple.data.length" dark depressed small color="primary" class="text-capitalize caption rounded-pill" @click.prevent="OpenInvoice('multiple')">
                  <v-icon class="mr-1" small>
                    mdi-printer-check
                  </v-icon>
                  PRINT ({{ dialogInvoice.multiple.data.length }}) INV
                </v-btn>
                <v-btn v-if="dialogInvoice.multiple.active && dialogInvoice.multiple.data.length" dark depressed small color="primary" class="text-capitalize caption rounded-pill ml-2" @click.prevent="OpenInvoice('multiple', false, true)">
                  <v-icon class="mr-1" small>
                    mdi-printer-check
                  </v-icon>
                  PRINT ({{ dialogInvoice.multiple.data.length }}) SHIPPING LABEL
                </v-btn>
              </div>
            </template>
            <template v-slot:item.stock_move="{ item }">
              <div v-if="view === 'pembelian'" class="my-4 caption">
                Buy From : {{ item.seller_vendor_name || '-' }}
                <div class="caption-small">
                  {{ parseInt(item.stock_purchase_completed || 0) }}/{{ parseInt(item.stock_purchase || 0) }} completed
                </div>
              </div>
              <v-chip v-else x-small :color="parseInt(item.seller) === parseInt($store.state.user.id) ? 'red darken-2' : 'blue darken-2'" dark class="my-4">
                {{
                  parseInt(item.sync)
                    ? parseInt(item.seller) === parseInt($store.state.user.id)
                      ? (parseInt(item.buyer) ? 'Out to : ' + item.buyer_name : 'Sync Out' + (item.dropship_name ? ' to ' + item.dropship_name : ''))
                      : parseInt(item.buyer) === parseInt($store.state.user.id)
                        ? (parseInt(item.seller) ? 'In from : ' + item.seller_name : 'Sync In' + (item.dropship_name ? ' from ' + item.dropship_name : ''))
                        : ''
                    : ''
                }}
              </v-chip>
              <v-chip v-if="!item.buyer" x-small color="grey" class="ml-1" dark>
                {{ $StockMissing(parseInt(item.buyer_none_reason)) }}
              </v-chip>
            </template>
            <template v-slot:item.id="{ item }">
              <div class="caption-small font-weight-bold py-1" style="min-width: 95px;line-height:120%;">
                <strong class="d-block">
                  #{{ item.id }}
                </strong>
                <small v-if="!parseInt(item.sync)">
                  {{ item.refid || '-' }}
                </small>
              </div>
            </template>
            <template v-slot:item.buyer_name="{ item }">
              <div class="caption">
                <!-- <v-chip v-if="parseInt(item.user) === parseInt(item.buyer)" x-small colod="primary">
                  creator
                </v-chip> -->
                {{ item.buyer_name || '-' }}
              </div>
            </template>
            <template v-slot:item.seller_name="{ item }">
              <div class="caption">
                <!-- <v-chip v-if="parseInt(item.user) === parseInt(item.seller)" x-small colod="primary">
                  creator
                </v-chip> -->
                {{ item.dropship_name ? item.seller_name + ' | ' + item.dropship_name : (item.seller_name || '-') }}
              </div>
            </template>
            <!-- <template v-slot:item.shipping="{ item }">
              <div class="py-1 caption">
                {{ item.shipping_address ? (item.shipping_address.split('||')[0] !== 'undefined' ? item.shipping_address.split('||')[0] + ' : ' : '-') : '-' }}<br>
                <small>{{ item.city_to }}</small>
              </div>
            </template> -->
            <template v-slot:item.price_total="{ item }">
              <div class="caption-small">
                {{ ((parseInt(item.price_total) - parseInt(item.price_discount || 0)) - parseInt(item.discount_package || 0)- parseInt(item.price_discount_treat || 0)) | price }}
              </div>
            </template>
            <template v-slot:item.price_discount="{ item }">
              <div class="caption-small">
                {{ (item.price_discount || 0) | price }}
              </div>
            </template>
            <template v-slot:item.price_discount_treat="{ item }">
              <div class="caption-small">
                {{ (item.price_discount_treat || 0) | price }}
              </div>
            </template>
            <template v-slot:item.shipping_total="{ item }">
              <div class="caption-small">
                {{ parseInt(item.shipping_total) | price }}
              </div>
            </template>
            <template v-slot:item.payment_total="{ item }">
              <div class="caption-small">
                {{ parseInt(item.payment_total) | price }}
              </div>
            </template>
            <!-- <template v-slot:item.payment_total_paid_total="{ item }">
              <div :class="[parseInt(item.payment_total_paid_total) < 0 ? 'red--text' : 'green--text']" class="caption-small">
                <div style="min-width: 120px;">paid <strong>{{ parseInt(item.payment_total_paid) | price }}</strong></div>
                <div>from <strong>{{ parseInt(item.payment_total) | price }}</strong></div>
              </div> -->
            <template v-slot:item.payment_total_paid_total="{ item }">
              <div :class="[parseInt(item.payment_total_paid_total) >= 0 ? 'green--text' : 'red--text']" class="caption-small">
                <v-tooltip top light>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="font-weight-bold">{{ parseInt(item.payment_total_paid_total) | price }}</span>
                  </template>
                  <span :class="[parseInt(item.payment_total_paid_total) >= 0 ? 'green--text' : 'red--text']" class="white caption-small px-2 py-1 font-weight-bold rounded-lg">{{ parseInt(item.payment_total_paid_total) >= 0 ? 'Paid' : 'Not Paid' }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:item.price_total_product="{ item }">
              <div class="caption-small">
                {{ parseInt(item.price_total_product || 0) | price }}
              </div>
            </template>
            <template v-slot:item.price_total_product_package="{ item }">
              <div class="caption-small">
                <!-- {{
                  (
                    parseInt(item.price_total_product_package_items) - parseInt(item.price_total_product_package)
                  ) | price
                }} -->
                {{ parseInt(item.discount_package || 0) | price }}               
              </div>
            </template>
            <template v-slot:item.price_total_reseller="{ item }">
              <div class="caption-small">                                               
                {{
                  (parseInt(item.price_total_reseller)
                    ? (
                        ((parseInt(item.price_total_product || 0) + parseInt(item.price_total_product_package || 0)) - parseInt(item.price_total_reseller || 0)) -
                        parseInt(item.price_total_product_package_items || 0) + parseInt(item.price_total_margin_discount)
                      )
                    : 0
                  ) | price
                }}
              </div>
            </template>
            <template v-slot:item.price_total_customer="{ item }">
              <div class="caption-small">
                {{ 
                  (parseInt(item.price_total_margin_discount)) | price
                }}                
<!--                 {{
                  (parseInt(item.price_total_customer)
                    ? (
                        ((parseInt(item.price_total_product || 0) + parseInt(item.price_total_product_package || 0)) - parseInt(item.price_total_customer || 0)) -
                        parseInt(item.price_total_product_package_items || 0)
                      )
                    : 0
                  ) | price
                }} -->
              </div>
            </template>
            <template v-slot:item.status="{ item }">
              <div v-if="view === 'buyer' && $store.state.user.role === 'pusat'" class="caption">
                {{ parseInt(item.sync) ? parseInt(item.seller) === parseInt($store.state.user.id) ? 'Stock Out' : parseInt(item.buyer) === parseInt($store.state.user.id) ? 'Stock In' : '' : '' }}
              </div>
              <v-select
                v-else
                v-model="item.status"
                :disabled="parseInt(item.user) !== parseInt($store.state.user.id)"
                :class="['st-' + parseInt(item.status), 'trx-status-' + item.id]"
                dense
                light
                hide-details
                dark
                :items="
                parseInt(item.status) === 99 || (parseInt(trxStatusFilter) === 100 && $store.state.user.role === 'pusat') ?
                [
                  { value: 0, name: 'Waiting Payment' },
                  { value: 1, name: 'Pending' },
                  { value: 2, name: 'Processed' },
                  { value: 3, name: 'Shipped' },
                  { value: 4, name: 'Complete' },
                  { value: 99, name: 'Cancel' },
                  { value: 100, name: 'Deleted' }
                ] : [
                  { value: 0, name: 'Waiting Payment' },
                  { value: 1, name: 'Pending' },
                  { value: 2, name: 'Processed' },
                  { value: 3, name: 'Shipped' },
                  { value: 4, name: 'Complete' },
                  { value: 99, name: 'Cancel' }
                ]"
                outlined
                item-value="value"
                item-text="name"
                class="select-default text-center caption-small rounded-xl"
                style="min-width: 115px;"
                @change="trxStatus(item, $event)"
              />
              <!-- <select
                v-else
                :id="'trx-status-' + item.id"
                :value="item.status"
                :disabled="parseInt(item.buyer) === parseInt($store.state.user.id)"
                :class="['st-' + parseInt(item.status)]"
                class="status-select text-capitalize white--text rounded-xl"
                @change.prevent="trxStatus(item)"
              >
                <option :value="0">
                  Waiting Payment
                </option>
                <option :value="1">
                  Pending
                </option>
                <option :value="2">
                  Processed
                </option>
                <option :value="3">
                  Shipped
                </option>
                <option :value="4">
                  Complete
                </option>
                <option :value="99">
                  Canceled
                </option>
                <option v-if="parseInt(item.status) === 99 || (parseInt(trxStatusFilter) === 100 && $store.state.user.role === 'pusat')" :value="100">
                  {{ parseInt(item.status) === 99 ? 'Delete' : 'Deleted ' + (new Date(item.updated).toLocaleString()) }}
                </option>
              </select> -->
              <!-- <div>
                {{ parseInt(item.status) === 0 ? 'Pending' : parseInt(item.status) === 1 ? 'Paid' : parseInt(item.status) === 2 ? 'Process' : parseInt(item.status) === 3 ? 'Shipping' : parseInt(item.status) === 4 ? 'Complete' : parseInt(item.status) === 99 ? 'Canceled' : '-' }}
              </div> -->
            </template>
            <template v-slot:item.created="{ item }">
              <div class="caption-small" style="min-width: 50px;">
                {{ $localDT(item.created, 'datelocal') || '-' }}
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn dark depressed :color="parseInt(item.sync) ? (!parseInt(item.seller_vendor) ? 'purple darken-3' : 'pink darken-1') : 'orange darken-3'" x-small class="text-capitalize align-center rounded-xl" @click.prevent="OpenInvoice(item)">
                <span v-if="parseInt(item.sync)" style="font-size: 8px;">
                  {{ !parseInt(item.seller_vendor) ? 'CARDSTOCK' : 'PURCHASE' }}
                </span>
                <span v-else>Invoice</span>
                <v-icon x-small class="caption-small ml-1">
                  mdi-file
                </v-icon>
              </v-btn>
              <v-btn v-if="parseInt(item.buyer) === parseInt($store.state.user.id) && parseInt(item.status) < 1" dark depressed color="yellow darken-3" x-small class="text-capitalize align-center rounded-xl" @click.prevent="UploadReceipt(item)">
                Upload
                <v-icon small class="caption-small ml-1">
                  {{ item.payment_receipt ? 'mdi-cloud-upload' : 'mdi-cloud-upload-outline' }}
                </v-icon>
                <!-- {{ item.payment_receipt ? 'Re-Upload Payment' : 'Upload Payment' }} -->
              </v-btn>
              <v-btn v-if="item.payment_receipt" :href="item.payment_receipt" color="green darken-3" class="text-capitalize align-center rounded-xl" dark depressed x-small target="_blank">
                View
                <v-icon small class="caption-small ml-1">
                  {{ item.payment_receipt ? 'mdi-receipt' : 'mdi-receipt' }}
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:footer>
              <div :class="[$vuetify.theme.dark ? '' : 'grey lighten-4']" class="pt-2 pb-3 px-4 px-md-8">
                <v-divider class="mt-2 mb-3" />
                <div class="d-flex justify-space-between flex-column flex-sm-row">
                  <div class="d-flex align-center justify-center justify-sm-start font-weight-bold">
                    Summary Reports :
                  </div>
                  <div class="d-flex justify-center align-center align-sm-end flex-column body-2">
                    <strong>
                      QUANTITY SUMMARY :
                    </strong>
                    <span v-if="view === 'pembelian'" class="font-weight-bold caption-small">
                      Stock Purchase Completed : {{ (parseInt(tableQuantity.total_quantity || 0) - parseInt(tableQuantity.summary_quantity_purchase_completed || 0)) }} item(s)
                    </span>
                    <span class="font-weight-bold caption-small">
                      Product : {{ parseInt(tableQuantity.total_quantity || 0) }} item(s)
                    </span>
                    <span v-if="view !== 'inventory' && view !== 'pembelian'" class="font-weight-bold caption-small">
                      Package : {{ parseInt(tableQuantity.total_quantity_package || 0) }} package(s)
                    </span>
                    <span v-if="view !== 'inventory' && view !== 'pembelian'" class="font-weight-bold caption-small">
                      Merchandise : {{ parseInt(tableQuantity.summary_quantity_merchandise || 0) }} item(s)
                    </span>
                    <span v-if="view !== 'inventory' && view !== 'pembelian'" class="font-weight-bold caption-small">
                      Additonal : {{ parseInt(tableQuantity.summary_quantity_additional || 0) }} item(s)
                    </span>
                    <div v-if="view !== 'inventory' && view !== 'pembelian'" class="d-flex justify-center align-center align-sm-end flex-column body-2">
                      <strong class="pt-2">
                        SUMMARY :
                      </strong>
                      <v-divider class="my-1" style="width: 55px;" />
                      <span class="font-weight-bold caption-small">
                        Product (Merchandise Included) {{ parseInt(tableQuantity.total_summary_normal) | price }}
                      </span>
                      <span class="caption-small">
                        Package Discount {{ parseInt(tableQuantity.package_discount) | price }} -
                      </span>
                      <span class="caption-small">
                        Merchandise {{ parseInt(tableQuantity.summary_merchandise) | price }} -
                      </span>
                      <v-divider class="my-1" style="width: 55px;" />
                      <span class="font-weight-bold caption-small">
                        Subtotal (Before Promo) {{ (parseInt(tableQuantity.total_summary_normal)-parseInt(tableQuantity.package_discount)-parseInt(tableQuantity.summary_merchandise)) | price }}
                      </span>
                      <v-divider class="my-1" style="width: 55px;" />
                      <span class="caption-small">
                        Reseller Promo {{ parseInt(tableQuantity.promo_reseller) | price }} -
                      </span>
                      <span class="caption-small">
                        Customer Promo {{ parseInt(tableQuantity.promo_customer) | price }} -
                      </span>
                      <v-divider class="my-1" style="width: 55px;" />
                      <span class="font-weight-bold caption-small">
                        Subtotal {{ parseInt(tableQuantity.total_summary) | price }}
                      </span>
                      <v-divider class="my-1" style="width: 55px;" />
                      <span class="caption-small">
                        Customer Treat {{ parseInt(tableQuantity.promo_treat) | price }} -
                      </span>
                      <span class="caption-small">
                        Checkout Discount {{ parseInt(tableQuantity.promo_global) | price }} -
                      </span>
                      <!-- <span class="caption-small">
                        Global Discount {{ parseInt(tableQuantity.promo_global) | price }}
                      </span> -->
                      <!-- <span class="font-weight-bold caption-small">
                        Discount {{ (parseInt(tableQuantity.total_summary_normal) - parseInt(tableQuantity.total_summary)) | price }}
                      </span> -->
                      <v-divider class="my-1" style="width: 150px;" />
                      <span class="font-weight-bold caption-small">
                        Omzet Total {{ (parseInt(tableQuantity.total_summary) - parseInt(tableQuantity.promo_global) - parseInt(tableQuantity.promo_treat)) | price }}
                      </span>
                      <span class="caption-small">
                        Average {{ ((parseInt(tableQuantity.total_summary) - parseInt(tableQuantity.promo_global) - parseInt(tableQuantity.promo_treat)) / tableTotal) | price }}
                      </span>
                      <v-divider class="my-1" style="width: 150px;" />
                      <span class="caption-small">
                        Shipping {{ parseInt(tableShipping) | price }}
                      </span>
                      <v-divider v-if="$roles($store.state.user.uid_roles, 'transaction-hpp')" class="my-1" style="width: 150px;" />
                      <span v-if="$roles($store.state.user.uid_roles, 'transaction-hpp')" class="font-weight-bold caption-small">
                        Hpp {{ tableQuantity.total_summary_hpp | price }}
                      </span>
                      <v-divider class="my-1" style="width: 150px;" />
                      <v-divider v-if="$roles($store.state.user.uid_roles, 'transaction-margin')" class="my-1" style="width: 75px;" />
                      <span v-if="$roles($store.state.user.uid_roles, 'transaction-margin')" class="font-weight-bold caption-small">
                        Margin {{ ((parseInt(tableQuantity.total_summary) - parseInt(tableQuantity.promo_global) - parseInt(tableQuantity.promo_treat)) - parseInt(tableQuantity.total_summary_hpp)) | price }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <!-- <template v-slot:footer>
              <div v-if="view !== 'inventory'" :class="[$vuetify.theme.dark ? '' : 'grey lighten-4']" class="pt-2 pb-3 px-4 px-md-8">
                <v-divider class="mt-2 mb-3" />
                <div class="d-flex justify-space-between flex-column flex-sm-row">
                  <div class="d-flex align-center justify-center justify-sm-start font-weight-bold">
                    Summary Reports :
                  </div>
                  <div class="d-flex justify-center align-sm-end flex-column body-2">
                    Omzet : {{ tableOmzet | price }} &sdot; Shipping : {{ tableShipping | price }}<br>
                    Shipping : {{ tableShipping | price }}<br>
                    <span class="font-weight-bold caption-small">Quantity Summary : {{ tableQuantity.total }} items <span class="font-weight-regular">from</span> Omzet {{ tableQuantity.total_summary | price }}</span>
                  </div>
                </div>
              </div>
            </template> -->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogInvoice.show"
      persistent
      scrollable
      max-width="750"
    >
      <v-card v-if="dialogInvoice.trx || dialogInvoice.multiple.active" class="prinv-inv">
        <v-card-text v-if="!dialogInvoice.multiple.active" class="pb-0 px-0 invoice-area" style="min-height: calc(100vh - 177px);">
          <InvoiceView
            v-if="dialogInvoice.show"
            v-model="NewInvoiceDataUpdated"
            :edit="dialogInvoice.edit"
            :trx="dialogInvoice.trx"
            :cart="dialogInvoice.cart"
            :label-print="dialogInvoice.labelPrint"
            @reload="TRX_GET_ONE_DETAIL"
            @create-xendit-invoice="CREATE_INVOICE"
          />
        </v-card-text>
        <v-card-text v-else class="pb-0 px-0 invoice-area" style="min-height: calc(100vh - 177px);">
          <InvoiceView
            v-for="(inv, iInv) in dialogInvoice.multiple.data" :key="'invoice-multiple-' + inv.id"
            :trx="inv"
            :multiple="true"
            :label-print="dialogInvoice.labelPrint"
          >
            <template v-if="(iInv + 1) !== dialogInvoice.multiple.data.length" v-slot:break-point>
              <div style="page-break-after: always;" />
            </template>
          </InvoiceView>
        </v-card-text>
        <v-divider />
        <v-card-actions v-if="!dialogInvoice.edit" class="px-4 no-print">
          <v-btn
            color="red"
            small
            text
            class="text-capitalize font-weight-normal"
            @click="dialogInvoice.show = false, TRX_GET(search)"
          >
            Close
          </v-btn>
          <v-spacer />
          <!-- <v-btn
            v-if="$store.state.screenWidth < 768"
            color="blue"
            small
            dark
            outlined
            class="text-capitalize font-weight-normal"
            @click="downloadInvoice()"
          >
            Download Invoice
          </v-btn> -->
          <template v-if="dialogInvoice.trx && !dialogInvoice.multiple.active">
            <v-btn v-if="$roles($store.state.user.uid_roles, 'finance') && parseInt(dialogInvoice.trx.sync) !== 1 && parseInt(dialogInvoice.trx.status) === 0 && (parseInt(dialogInvoice.trx.user) === parseInt($store.state.user.id))" small color="teal" class="mr-1 text-capitalize" text dark @click.prevent="dialogInvoice.edit = true">
              <v-icon x-small class="mr-1">
                mdi-pencil
              </v-icon>
              Edit
            </v-btn>
          </template>
          <v-btn
            v-if="$store.state.screenWidth > 767"
            color="blue"
            small
            dark
            outlined
            class="text-capitalize font-weight-normal caption-small"
            @click="print()"
          >
            <v-icon small class="mr-1">
              mdi-printer
            </v-icon>
            Print {{ (dialogInvoice.multiple.active ? 'All' : parseInt(dialogInvoice.trx.sync) ? (parseInt(dialogInvoice.trx.seller) ? 'CARDSTOCK' : 'INV') : 'Invoice') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else class="px-4 no-print">
          <v-spacer />
          <v-btn
            color="orange"
            small
            text
            class="text-capitalize font-weight-normal mr-1"
            @click="dialogInvoice.edit = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="teal"
            small
            dark
            depressed
            class="text-capitalize font-weight-normal"
            @click="UpdateInvoice()"
          >
            <v-icon small class="mr-1">
              mdi-content-save-outline
            </v-icon>
            Update Invoice
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogShopee />
  </div>
</template>

<script>
let searchTimeout = null
export default {
  data: (vm) => ({
    dates: [],
    menu: false,
    view: 'seller',
    search: '',
    tableLoading: true,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 10,
    tableOmzet: 0,
    tableShipping: 0,
    tableQuantity: {
      summary_quantity_purchase_completed: 0,
      total_quantity: 0,
      total_summary_normal: 0,
      total_summary: 0,
      total_quantity_package: 0,
      total_summary_hpp: 0,
      promo_reseller: 0,
      promo_customer: 0,
      promo_treat: 0,
      promo_global: 0,
      package_discount: 0,
      summary_quantity_merchandise: 0,
      summary_quantity_additional: 0,
      summary_merchandise: 0
    },
    options: {},
    trxStatusMemberType: '',
    trxSyncType: 0,
    trxStatusFilter: 9999,
    trxStokistFilter: vm.$store.state.user.id,
    trxUserAdminFilter: 0,
    trxSourceFilter: '',
    trxSourcePembelian: 1,
    headersInventory: [
      { text: '#ID', value: 'id' },
      { text: 'Date', value: 'created', align: 'center' },
      {
        text: 'Stock Move',
        align: 'start',
        value: 'stock_move'
      },
      // { text: 'Ship To', value: 'shipping', sortable: false },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
    headers: [
      { text: '#ID', value: 'id' },
      // {
      //   text: 'Seller',
      //   align: 'start',
      //   value: 'seller_name'
      // },
      // {
      //   text: 'Buyer',
      //   align: 'start',
      //   value: 'buyer_name'
      // },
      // { text: 'Ship To', value: 'shipping', sortable: false },
      { text: 'Product Selling', value: 'price_total_product', align: 'end' },
      { text: 'Package Discount', value: 'price_total_product_package', align: 'end' },
      { text: 'Promo Reseller', value: 'price_total_reseller', align: 'end' },
      { text: 'Promo Customer', value: 'price_total_customer', align: 'end' },
      { text: 'Checkout Discount', value: 'price_discount', align: 'end' },
      { text: 'Treat Customer', value: 'price_discount_treat', align: 'end' },
      { text: 'Omzet Total', value: 'price_total', align: 'end' },
      { text: 'Shipping', value: 'shipping_total', align: 'end' },
      { text: 'Total', value: 'payment_total', align: 'end' },
      // { text: 'Balance', value: 'payment_total_paid_total', align: 'end' },
      { text: 'Date', value: 'created', align: 'center' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
    dialogInvoice: {
      show: false,
      labelPrint: false,
      edit: false,
      trx: null,
      cart: [],
      multiple: {
        active: false,
        data: []
      }
    },
    NewInvoiceDataUpdated: null
  }),
  watch: {
    NewInvoiceDataUpdated (v) {
      // console.log(v)
    },
    search: function (v) {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.TRX_GET(v)
      }, 300)
    },
    dates: function (fromTo) {
      if (fromTo.length === 2) {
        this.TRX_GET(this.search)
      }
    },
    trxStatusMemberType: function () {
      this.TRX_GET(this.search)
    },
    trxSyncType: function () {
      this.TRX_GET(this.search)
    },
    trxStatusFilter: function () {
      this.TRX_GET(this.search)
    },
    trxStokistFilter: function () {
      this.TRX_GET(this.search)
    },
    trxUserAdminFilter: function () {
      this.TRX_GET(this.search)
    },
    trxSourceFilter: function () {
      this.TRX_GET(this.search)
    },
    trxSourcePembelian: function () {
      this.TRX_GET(this.search)
    },
    view: function (v) {
      this.trxSyncType = 0
      this.trxSourcePembelian = 1
      if (v !== 'seller') {
        this.trxStatusMemberType = ''
      }
      this.TRX_GET(this.search)
    },
    options: {
      handler () {
        this.TRX_GET(this.search)
      },
      deep: true
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  mounted () {
    this.dates = [this.$localDT(new Date(), 'datedefault'), this.$localDT(new Date(), 'datedefault')]
    // this.TRX_GET()
    this.$store.dispatch('STOKIST_GET')
  },
  methods: {
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    },
    UpdateInvoice () {
      if (this.NewInvoiceDataUpdated) {
        if (this.dialogInvoice.trx.buyer !== this.NewInvoiceDataUpdated.trx.buyer && parseInt(this.dialogInvoice.trx.payment_total_paid)) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Ops, you\'ve change different customer. Deposit history should be cleared!' })
          return false
        }
        if (!confirm('Update this Invoice ?')) {
          return false
        }
        this.$store.dispatch('TRX_INVOICE_UPDATE', this.NewInvoiceDataUpdated)
          .then(async (res) => {
            if (res.status) {
              this.TRX_GET(this.search)
              this.TRX_GET_ONE_DETAIL(this.NewInvoiceDataUpdated.trx.id)
              await this.OpenInvoice(Object.assign({}, this.NewInvoiceDataUpdated.trx), true)
              this.$store.commit('SNACKBAR', { show: true, text: 'Invoice Updated!' })
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: 'Failed update!' })
            }
          })
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'No data updated!' })
      }
    },
    UploadReceipt (item) {
      const f = document.getElementById('imageUploaderReceipt')
      if (f) {
        f.remove()
      }
      const $ = this
      const el = document.createElement('input')
      el.setAttribute('id', 'imageUploaderReceipt')
      el.setAttribute('type', 'file')
      el.setAttribute('class', 'file-uploader')
      el.setAttribute('accept', '.jpg,.jpeg,.png')
      el.addEventListener('change', function (e) {
        $.UPLOAD_HANDLER(e, item)
      })
      document.body.appendChild(el)
      el.click()
    },
    async UPLOAD_HANDLER (event, item) {
      /* eslint-disable */
      const $ = this
      await $.$imgtoBase64(event, async function (res) {
        if (res.status) {
          // $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            size: 1000
          }).then((resUpload) => {
            $.$store.dispatch('TRX_UPDATE', {
              id: item.id,
              buyer: $.$store.state.user.id,
              payment_receipt: resUpload.data.data.secure_url,
              status: 1
            }).then(res => {
              const findIndex = $.table.findIndex(r => parseInt(r.id) === parseInt(item.id))
              if (findIndex > -1) {
                $.$store.commit('SNACKBAR', { show: true, text: 'Uploaded, reviewed by admin' })
                $.table[findIndex].status = 1
                $.table[findIndex].payment_receipt = resUpload.data.data.secure_url
              }
            })
          })
        } else {
          res.data === 'file_size'
            ? $.$store.commit('SNACKBAR', { show: true, message: 'Ops, file image is too large. Max size (images < 5Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    },
    print () {
      window.print()
    },
    async OpenInvoice (item, reload, isPrintShippingLabel) {
      this.dialogInvoice.labelPrint = isPrintShippingLabel || false
      if (item === 'multiple') {
        setTimeout(() => {
          this.dialogInvoice.show = true
        }, 300)
      } else if (item) {
        this.dialogInvoice.multiple.active = false
        this.NewInvoiceDataUpdated = null
        if (!reload) {
          this.dialogInvoice.edit = false
        }
        this.dialogInvoice.trx = Object.assign({}, item)
        this.dialogInvoice.cart = []
        this.dialogInvoice.show = true
        if (reload) {
          this.dialogInvoice.edit = false
        }
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'Ops, invalid invoice!' })
      }
    },
    downloadInvoice () {
      this.$Capture(document.querySelector('.print-sheet')).then((canvas) => {
        const link = document.createElement('a')
        link.setAttribute('download', 'invoice-' + this.dialogInvoice.data.invoice.id + '.png')
        link.setAttribute('href', canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
        link.click()
        this.$store.commit('SNACKBAR', { show: true, text: 'Downloaded' })
      })
    },
    TRX_GET_RELOAD () {
      this.TRX_GET(this.search)
    },
    TRX_GET_ONE_DETAIL (trxid) {
      this.$store.dispatch('TRX_GET_ONE_DETAIL', trxid)
        .then((res) => {
          if (res.status) {
            this.dialogInvoice.trx = Object.assign({}, res.data.data)
          }
        })
    },
    TRX_GET (q) {
      this.dialogInvoice.multiple.active = false
      this.dialogInvoice.multiple.data = []
      this.tableQuantity.total_quantity = 0
      this.tableQuantity.total_quantity_package = 0
      this.tableQuantity.total_summary = 0
      this.tableQuantity.total_summary_normal = 0
      this.tableQuantity.total_summary_hpp = 0
      this.tableQuantity.promo_reseller = 0
      this.tableQuantity.promo_customer = 0
      this.tableQuantity.promo_treat = 0
      this.tableQuantity.promo_global = 0
      this.tableQuantity.package_discount = 0
      this.tableQuantity.summary_quantity_merchandise = 0
      this.tableQuantity.summary_quantity_additional = 0
      this.tableQuantity.summary_merchandise = 0
      this.tableQuantity.summary_quantity_purchase_completed = 0

      this.tableLoading = true
      const fromTo = this.GetDates()
      const withStatus = this.trxStatusFilter !== 9999 ? '&status=' + this.trxStatusFilter : ''
      const withStokist = this.trxStokistFilter > 0 ? '&stokist=' + this.trxStokistFilter : ''
      const withUserAdmin = this.trxUserAdminFilter > 0 ? '&uid=' + this.trxUserAdminFilter : ''
      const withTrxSource = this.trxSourceFilter ? '&trx_source=' + this.trxSourceFilter : ''

      const withRole = this.trxStatusMemberType ? '&memberType=' + this.trxStatusMemberType : ''
      let withSyncType = this.trxSyncType ? '&syncType=' + this.trxSyncType : ''
      let withSyncTypePembelian = this.trxSourcePembelian ? '&syncTypePembelian=' + this.trxSourcePembelian : ''
      
      if (this.view === 'pembelian') {
        withSyncType = '&syncType=1'
        withSyncTypePembelian = '&syncTypePembelian=1'
      } else {
        withSyncTypePembelian = ''
      }
      const view = this.view === 'pembelian' ? 'inventory' : this.view

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const params = '?view=' + view + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('TRX_GET', params + (q ? ('&q=' + q) : '') + (fromTo || '') + (withStatus || '') + (withStokist || '') + (withUserAdmin || '') + (withTrxSource || '') + (withRole || '') + (withSyncType || '') + (withSyncTypePembelian || '')).then((res) => {
        if (res.status) {
          this.table = res.data.data.data.map((r) => {
            r.status_old = parseInt(r.status)
            return r
          })
          this.tableTotal = parseInt(res.data.data.total) || 0
          this.tableOmzet = parseInt(res.data.data.omzet) || 0
          this.tableShipping = parseInt(res.data.data.shipping) || 0

          this.tableQuantity.summary_quantity_purchase_completed = parseInt(res.data.data.mutation.summary_quantity_purchase_completed) || 0
          this.tableQuantity.total_quantity = parseInt(res.data.data.mutation.summary_quantity) || 0
          this.tableQuantity.total_quantity_package = parseInt(res.data.data.mutation.summary_quantity_package) || 0
          this.tableQuantity.total_summary = parseInt(res.data.data.mutation.summary_quantity_nominal) || 0
          this.tableQuantity.total_summary_normal = parseInt(res.data.data.mutation.summary_quantity_nominal_normal) || 0
          this.tableQuantity.total_summary_hpp = parseInt(res.data.data.mutation.summary_hpp) || 0
          this.tableQuantity.promo_reseller = parseInt(res.data.data.mutation.summary_promo_reseller) || 0
          this.tableQuantity.promo_customer = parseInt(res.data.data.mutation.summary_promo_customer) || 0
          this.tableQuantity.promo_treat = parseInt(res.data.data.mutation.summary_discount_treat) || 0
          this.tableQuantity.promo_global = parseInt(res.data.data.mutation.summary_global_discount) || 0
          this.tableQuantity.package_discount = parseInt(res.data.data.mutation.summary_package_discount) || 0
          this.tableQuantity.summary_quantity_merchandise = parseInt(res.data.data.mutation.summary_quantity_merchandise) || 0
          this.tableQuantity.summary_quantity_additional = parseInt(res.data.data.mutation.summary_quantity_additional) || 0
          this.tableQuantity.summary_merchandise = parseInt(res.data.data.mutation.summary_merchandise) || 0
        } else {
          this.table = []
          this.tableTotal = 0
          this.tableOmzet = 0
          this.tableShipping = 0
          this.tableQuantity.total = 0
          this.tableQuantity.summary = 0
          this.tableQuantity.total_summary_normal = 0
          this.tableQuantity.total_summary_hpp = 0
          this.tableQuantity.promo_reseller = 0
          this.tableQuantity.promo_customer = 0
          this.tableQuantity.promo_treat = 0
          this.tableQuantity.promo_global = 0
          this.tableQuantity.package_discount = 0
        }
        this.tableLoading = false
      })
    },
    async trxStatus (item, newStatus) {
      const findIndex = this.table.findIndex((r) => parseInt(r.id) === parseInt(item.id))
      let next = true
      const data = Object.assign({}, item)
      const oldStatus = parseInt(data.status_old)
      newStatus = parseInt(newStatus) || 0
      let awb = null
      if (newStatus === 3 && !item.shipping_awb) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm_value',
          title: 'Set ' + data.courier_name + ' AWB Number:',
          text: ''
        }).then((res) => {
          if (res.result) {
            data.shipping_awb = res.value
          } else {
            this.table[findIndex].status = oldStatus
            next = false
            // this.TRX_GET_RELOAD()
          }
        })
      }
      data.status = newStatus
      if (newStatus === 100) {
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm',
          title: 'Confirm',
          text: 'Are you sure want to delete this transaction?'
        }).then((res) => {
          if (!res.result) {
            next = false
            this.table[findIndex].status = oldStatus
            // this.TRX_GET_RELOAD()
          }
        })
      } else if (newStatus !== 3) {
        let isAffectedStock = false
        if((oldStatus === 0 || oldStatus === 1 || oldStatus === 99) && (newStatus === 2 || newStatus === 3 || newStatus === 4)){//dari waiting,pending atau cancel ke processed, shipped, complete
          isAffectedStock = true
        } else if((newStatus === 0 || newStatus === 1 || newStatus === 99) && (oldStatus === 2 || oldStatus === 3 || oldStatus === 4)){//dari processed, shipped, complete ke waiting,pending atau cancel
          isAffectedStock = true
        }
        await this.$store.dispatch('POPUP', {
          t: this,
          show: true,
          type: 'confirm',
          title: 'Confirm',
          text: isAffectedStock ? 'This action will affect the existing stock. Are you sure you want to change the status?' : 'Are you sure you want to change the status?'
        }).then((res) => {
          if (!res.result) {
            next = false
            this.table[findIndex].status = oldStatus
            // this.TRX_GET_RELOAD()
          }
        })
      }
      if (!next) {
        return false
      }
      await this.$store.dispatch('TRX_UPDATE', data).then(res => {
        if (!res.status) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          // this.TRX_GET_RELOAD()
          this.table[findIndex].status = oldStatus
        } else {
          if (newStatus === 100) {
            if (findIndex > -1) {
              this.table.splice(findIndex, 1)
            }
            this.$store.commit('SNACKBAR', { show: true, text: 'Transaction deleted' })
          } else {
            if (findIndex > -1) {
              this.table[findIndex].status = newStatus
              if (awb) {
                this.table[findIndex].shipping_awb = awb
              }
              this.TRX_GET_RELOAD()
            }
            this.$store.commit('SNACKBAR', { show: true, text: 'Transaction status updated' })
          }
        }
      })
    },
    CREATE_INVOICE (trx) {
      if (trx) {
        if (parseInt(trx.id)) {
          this.$store.dispatch('CREATE_INVOICE', {
            externalID: trx.id + '/' + trx.refid,
            customerName: trx.buyer_name || '-',
            customerEmail: trx.buyer_email || '-',
            customerPhone: '+62' + trx.buyer_phone,
            customerAddress: trx.city_to || '-',
            description: trx.seller_invoice_note || '-',
            amount: parseInt(trx.payment_total)
          }).then((res) => {
            if (res.status) {
              if (res.data.data.response) {
                this.dialogInvoice.trx.payment_invoice = res.data.data.data.invoice_url   
                this.$store.commit('SNACKBAR', { show: true, text: 'Xendit Invoice Created!' })
              }
            }
          })
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Invalid ID!' })
        }
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid TRX!' })
      }
    }
  }
}
</script>

<style lang="scss">
.select-default {
  width: 100px;
  .v-input__slot {
    height: 30px!important;
    min-height: 30px!important;
    .v-input__append-inner {
      margin-top: 4px!important;
    }
  }
}
</style>
